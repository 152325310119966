<template>
  <div class="grid">
    <div
      class="col-12 lg:col-6"
      v-for="(value, key) in admin_salon.result_iframe"
      :key="key"
      :class="{'p-0': key === 'barber_rating' || key === 'list_transactions'}"
    >
      <div class="card mb-0" v-if="key !== 'barber_rating' && key !== 'list_transactions'">
        <h2 class="mb-3 tw-font-bold tw-text-2xl tw-text-gray-500">
          Salon Transaction Report - <span class="capitalize">{{ key }}</span>
        </h2>
        <div>
          Filter : 
          <template v-if="key === 'daily'">
            <Calendar v-model="form.daily" id='input-daily' placeholder="2022-12-31" dateFormat="yy-mm-dd" @date-select="selectedDaily" />
          </template>
          <template v-else-if="key === 'weekly'">
            <Calendar v-model="form.weekly" id='input-weekly' placeholder="2022-12-31" dateFormat="yy-mm-dd" @date-select="selectedWeekly" />
          </template>
          <template v-else-if="key === 'monthly'">
            <Calendar v-model="form.monthly" id='input-monthly' placeholder="2022-12" view="month" dateFormat="yy-mm" @date-select="selectedMonthly" />
          </template>
          <template v-else-if="key === 'annual'">
            <Calendar v-model="form.annual" id='input-annual' placeholder="2022" view="year" dateFormat="yy" @date-select="selectedAnnual" />
          </template>
        </div>
        <div class="text-900 font-medium text-xl">
          <iframe
            id="iframe"
            :src="value"
            width="100%"
            height="480"
            frameborder="0"
            allowtransparency>
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthRole } from '@/composables/auth';

export default {
  setup() {
    const { isAdminPriti, getUser } = useAuthRole()
    return { isAdminPriti, getUser }
  },
  props: ['id'],
  data() {
    return {
      METABASE_URL: process.env.VUE_APP_METABASE_URL,
      moment: require('moment'),
      salon_id: '',
      current_date: '',
      current_dateFrom: '',
      current_month: '',
      current_year: '',
      form: {
        daily: '',
        weekly: '',
        monthly: '',
        annual: '',
      },
      admin_salon: {
        metabase_data: {
          daily: 13,
          weekly: 14,
          monthly: 15,
          annual: 16,
        },
        result_iframe: {
          daily: '',
          weekly: '',
          monthly: '',
          annual: '',
        },
      },
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isAdminPriti();
      return isAdmin;
    },
  },
  mounted() {
    this.getCurrentDateTime()
    this.getSalonID()
  },
  methods: {
    getCurrentDateTime() {
      this.current_date = this.moment().format('YYYY-MM-DD')
      this.current_dateFrom = this.moment().format('YYYY-MM-DD')
      this.current_month = this.moment().format('MM')
      this.current_year = this.moment().format('YYYY')
    },
    getSalonID() {
      this.$http.get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user-info`).then(response => {
        this.salon_id = response.data.data.salon_id

        for (const [key, value] of Object.entries(this.admin_salon.metabase_data)) {
          this.getData(key, value)
        }
      })
    },
    selectedDaily(date) {
      this.current_date = this.moment(date).format('YYYY-MM-DD')
      this.getData('daily', 13)

      let $input = document.getElementById('input-daily')
      $input.childNodes[0].blur()
    },
    selectedWeekly(date) {
      this.current_dateFrom = this.moment(date).format('YYYY-MM-DD')
      this.getData('weekly', 14)

      let $input = document.getElementById('input-weekly')
      $input.childNodes[0].blur()
    },
    selectedMonthly(date) {
      this.current_month = this.moment(date).format('MM')
      this.current_year = this.moment(date).format('YYYY')
      this.getData('monthly', 15)

      let $input = document.getElementById('input-monthly')
      $input.childNodes[0].blur()
    },
    selectedAnnual(date) {
      this.current_year = this.moment(date).format('YYYY')
      this.getData('annual', 16)

      let $input = document.getElementById('input-annual')
      $input.childNodes[0].blur()
    },
    getData(key, question) {
      const jwt = require("jsonwebtoken");

      const METABASE_SITE_URL = process.env.VUE_APP_METABASE_URL;
      const METABASE_SECRET_KEY = process.env.VUE_APP_METABASE_SECRET_KEY;

      let params;

      if (key === 'daily') {
        params = { date: this.current_date }
      } else if (key === 'weekly') {
        params = { date: this.current_dateFrom }
      } else if (key === 'monthly') {
        params = { month: this.current_month, year: this.current_year }
      } else if (key === 'annual') {
        params = { year: this.current_year }
      }

      let payload = {
        resource: { question: question },
        params: { 
          salon_id: this.salon_id,
          ...params,
        },
        exp: Math.round(Date.now() / 1000) + 60 * 60, // 60 minute expiration
      };
      let token = jwt.sign(payload, METABASE_SECRET_KEY);
      this.admin_salon.result_iframe[key] = METABASE_SITE_URL + "/embed/question/" + token + `#bordered=false&titled=false`;
    },
  },
};
</script>
